const config = {
    /**
     * 环境变量
     * prod 正式环境
     * pre 预发布环境
     * test 测试环境
     * dev 开发环境
     * 运维人员必须配置此端口
     */
    env: 'prod',

    /**
     * 官网前端服务器监听的端口
     * 运维人员必须配置此端口
     */
    port: '6363',

    /**
     * 日志文件路径
     * 运维人员必须配置此路径
     */
    log_path: './logs'
};

module.exports = config;
