/**
 * 前端，公用变量定义
 * key的命名为大写下划线格式, 如 ：COMMON_PAGE_NAME
 */

export const COOKIE_KEY = {
    UID: 'id',
    USER_INFO: 'userInfo',
    TOKEN: 'token',
    ODP_MENU_COLLAPSED: 'ODP_MENU_COLLAPSED'
};