import { createSlice } from '@reduxjs/toolkit';
import { setUserInfoCookie, getUserInfoFromCookie, clearUserInfoCookie } from '@util';

const { userInfo: {
    user_name,
    account,
    role,
    id,
    avatar,
}, token } = getUserInfoFromCookie();
// 初始用户状态
const initialState = {
    user_name: user_name || '',
    account: account || '',
    role: role || '',
    id: id || '',
    avatar: avatar || '',
    isLoggedIn: id ? true : false,  // 是否已登录
    token: token || ''
};

// 创建用户信息的 slice
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action) => {
            const { user_name, account, role, avatar, id, token } = action.payload;
            state.user_name = user_name;
            state.account = account;
            state.role = role;
            state.avatar = avatar;
            state.id = id;
            state.isLoggedIn = true;
            state.token = token;
            setUserInfoCookie({
                userInfo: { user_name, account, role, avatar, id },
                token: token
            });
        },
        updateUserInfo: (state, action) => {
            const { user_name, account, role, avatar, id } = action.payload;
            state.user_name = user_name ?? state.user_name;
            state.account = account ?? state.account;
            state.role = role ?? state.role;
            state.avatar = avatar ?? state.avatar;
            state.id = id ?? state.id;
            state.isLoggedIn = true;
            state.token = token;
        },
        logout: (state) => {
            state.user_name = '';
            state.account = '';
            state.role = '';
            state.avatar = '';
            state.id = '';
            state.isLoggedIn = false;  // 登录状态为 false
            state.token = '';
            clearUserInfoCookie();
        },
    }
});

// 导出 actions 和 reducer
export const { login, updateUserInfo, logout } = userSlice.actions;
export default userSlice.reducer;