import { COOKIE_KEY } from './constants';

// 判断是否是PC端
export const isPC = () => {
    // 只判断PC端
    // 获取视口宽度和高度
    const viewportWidth = window.innerWidth;
    // const viewportHeight = window.innerHeight;
    const isPC = viewportWidth > 1280;
    return isPC;
}

export const isPCclassName = () => {
    // 只判断PC端
    // 获取视口宽度和高度
    const viewportWidth = window.innerWidth;
    // const viewportHeight = window.innerHeight;
    const isPC = viewportWidth > 1280;
    return isPC ? '' : 'mobile';
}

export const normalRequestErrorHandler = (res) => {
    /* eslint-disable no-console */
    console.error(res.message || res.msg || res);
};

/**
 * 退出登陆后把uid和token从cookie移除
 */
export const clearUserInfoCookie = () => {
    localStorage.removeItem(COOKIE_KEY.TOKEN);
    localStorage.removeItem(COOKIE_KEY.USER_INFO);
};

/**
 * 获取uid和token
 */
export const getUserInfoFromCookie = () => {
    const token = localStorage.getItem(COOKIE_KEY.TOKEN) || '';
    let userInfo = localStorage.getItem(COOKIE_KEY.USER_INFO);
    if (userInfo) {
        try {
            userInfo = JSON.parse(userInfo);
        } catch (error) {
            userInfo = {};
        }
    } else {
        userInfo = {};
    }
    return { userInfo: userInfo, token };
};

/**
 * 登陆后把uid和token设进cookie
 */
export const setUserInfoCookie = ({ userInfo, token }) => {
    userInfo && localStorage.setItem(COOKIE_KEY.USER_INFO, JSON.stringify(userInfo));
    token && localStorage.setItem(COOKIE_KEY.TOKEN, token);
};