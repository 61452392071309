import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Empty, Skeleton } from 'antd-mobile'
import { normalRequestErrorHandler } from '@util';
import { getPagesByType } from '@api';
import styles from './index.module.less';
// 导入编辑器的样式
import 'react-markdown-editor-lite/lib/index.css';
export default function Article() {
  // 使用 useLocation Hook 来获取传递的 state
  const location = useLocation();
  const { title, key } = location.state || {}; // 如果 state 为空，提供默认值
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  
  // 获取用户列表
  const getPagesByTypeList = useCallback(() => {
    setLoading(true);
    getPagesByType(key ? key : 'all')
      .then(res => {
        console.log(res);
        setList(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        normalRequestErrorHandler(err);
      });
  }, [key]);

  useEffect(() => {
    // 这个代码相当于 componentDidMount
    getPagesByTypeList();
    // 可选：返回一个清理函数，相当于 componentWillUnmount
    return () => {
    };
  }, [location, getPagesByTypeList]); 

  // 点击文章跳转
  const navlink = useNavigate();
  const toPages = (e) => {
    navlink(`/pages/article/${e.type}/${e.id}`)
  };


  return (
    <div className={styles.article}>
      <div className={styles['article-header']}>
        <h1 className={styles['article-header-title']}>{ title }</h1>
      </div>
      {
        loading ? (
          <div className={styles['article-list']}>
            {
              [1,2,3].map((item) => 
                <div key={item} className={styles['article-list-item']}>
                  <div className={styles['article-list-item-banner']}>
                    <Skeleton animated className={styles.customSkeleton} />
                  </div>
                  <h3><Skeleton.Title animated style={{width: '100%'}} /></h3>
                </div>
              )
            }
          </div>
        ) :
        list.length > 0 ?
          (<div className={styles['article-list']}>
            {
              list.map((item, index) => {
                return (
                  <div key={item.id} className={styles['article-list-item']} onClick={() => toPages(item)}>
                    <div className={styles['article-list-item-banner']}>
                      <img src={item.banner} alt={item.title} />
                    </div>
                    <p>{item.type_desc}</p>
                    <h3>{item.title}</h3>
                    <p className={styles['article-list-item-desc']}>{item.remark}</p>
                  </div>
                )
              })
            }
          </div>)
        : (<Empty
            style={{ padding: '64px 0' }}
            imageStyle={{ width: 128 }}
            description='暂无数据'
          />)
      }
    </div>
  )
}
