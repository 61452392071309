import { useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { NavLink, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Button, Layout, Menu, theme, Avatar, Dropdown } from 'antd';
import { logout } from '@store/reducers/userReducer';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from './index.module.less';
import { putLogout } from '@api';
import { normalRequestErrorHandler } from '@util';
const { Header, Sider, Content } = Layout;
const logo = 'https://pmk.oss-cn-shenzhen.aliyuncs.com/pmk_web/image/logo.png';
export default function Admin() {
  const user = useSelector((state) => state.user, shallowEqual);
  const [collapsed, setCollapsed] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  // 退出登录
  const logoutClick = () => {
    putLogout()
      .then(() => {
        window.location.replace('/pages/home');
        dispatch(logout());
      })
      .catch(normalRequestErrorHandler);
  };
  const items = [
    {
      key: '1',
      label: (
        <NavLink to="/pages/home" target="_blank">
          <span>返回首页</span>
        </NavLink>
      ),
    },
    { key: '2', label: <span onClick={logoutClick}>退出登录</span> }
  ];
  // 主题背景颜色
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  // 菜单点击跳转
  const navlink = useNavigate();
  const menuOnClick = ({ key, keyPath }) => {
    navlink(key);
  };
  return (
    <Layout className={styles.admin}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          background: '#fff',
          borderRight: '1px solid #ccc'
        }}
      >
        <div className={styles['admin-logo']}>
          <img className={styles['admin-logo-img']} src={logo} alt="logo" onClick={() => menuOnClick({key: '/pages/home'})}/>
        </div>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[pathname]}
          style={{
            background: '#fff',
            borderInlineEnd: '0'
          }}
          items={[
            {
              key: '/admin/article_management',
              icon: <VideoCameraOutlined />,
              label: '文章管理',
            },
            {
              key: '/admin/user_management',
              icon: <UserOutlined />,
              label: '用户管理',
            }
          ]}
          onClick={menuOnClick}
        />
      </Sider>
      <Layout>
        <Header
          className={styles['admin-header']}
          style={{
            background: colorBgContainer
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
              borderRadius: 0
            }}
          />
          <Dropdown menu={{items}} placement="bottomRight" arrow={{ pointAtCenter: true }}>
            <Avatar src={<img src={user.avatar} alt="avatar" />} />
          </Dropdown>
        </Header>
        <Content
          className={styles['admin-content']}
          style={{
            background: colorBgContainer,
          }}
        >
          <Outlet/>
        </Content>
      </Layout>
    </Layout>
  );
}
