import React, { useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload, Modal } from 'antd';
import { getUserInfoFromCookie, clearUserInfoCookie } from '@util';
import { uploadFile } from '@api';
import './index.less';
const { userInfo: {
  id,
}, token } = getUserInfoFromCookie();

// const getBase64 = (img, callback) => {
//     const reader = new FileReader();
//     reader.addEventListener('load', () => callback(reader.result));
//     reader.readAsDataURL(img);
// };
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('请上传JPG/PNG格式文件！');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('图片必须小于2MB！');
  }
  return isJpgOrPng && isLt2M;
};

export default function UploadImage({ value, onChange, size }) {
  const [loading, setLoading] = useState(false);
  // const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    const infoData = info.file;
    if (infoData.status === 'error') {
      setLoading(false);
      if (infoData.response.code === 10000) {
        clearUserInfoCookie();
        return Modal.warning({
          title: '用户登录凭证失效!',
          content: (
            <>
              已退出登录，点击确定返回首页
            </>
          ),
          onOk: () => {
            window.location.replace('/pages/home/');
          }
        });
      }
      message.error(infoData.response.message);
      return;
    }
    if (infoData.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (infoData.status === 'done') {
      setLoading(false);
      const url = infoData.response.data;
      // setImageUrl(url);
      onChange(url);
      // getBase64(info.file.originFileObj, (url) => {
      // });
    }
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
        width: size ? size.width || '100px' : '100px',
        height: size ? size.height || '100px' : '100px'
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
      style={{
        marginTop: 8
      }}
      >
        上传
      </div>
    </button>
  );
  return (
    <Upload
      name="file"
      listType="picture-card"
      className="avatar-uploader"
      headers={{
        token: token,
        uid: id
      }}
      showUploadList={false}
      action={uploadFile()}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {value ? (
        <img
          src={value}
          alt="avatar"
          style={{
            width: size ? size.width || '100%' : '100px',
            height: size ? size.height || 'auto' : '100px'
          }}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  )
}
