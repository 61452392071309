import { useEffect, useState } from 'react';
import { Space, Button, Table, Modal, Form, Input, message, Avatar, Popconfirm } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import styles from './index.module.less';
import { getUsersList, postUsers, updateUsers, deleteUsers } from '@api';
import { normalRequestErrorHandler } from '@util';
import UploadImage from '@components/UploadImage';
export default function UserManagement() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const { Column } = Table;
  const [userForm] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    // 这个代码相当于 componentDidMount
    getUsers();

    // 可选：返回一个清理函数，相当于 componentWillUnmount
    return () => {
    };
  }, []); 

  // 获取用户列表
  const getUsers = () => {
    setTableLoading(true);
    getUsersList()
      .then(res => {
        res.forEach(e => e.key = e.id);
        setDataSource(res);
        setTableLoading(false);
      })
      .catch(err => {
        setTableLoading(false);
        normalRequestErrorHandler(err)
      });
  };

  // 表单添加/编辑判断
  const [isEdit, setIsEdit] = useState(false);
  const [editUserId, setEditUserId] = useState();
  // 表单初始化参数
  const formData = {
    account: '',
    password: '',
    user_name: '',
    avatar: ''
  };
  // 显示弹窗
  const showModal = (item) => {
    if (item) {
      userForm.setFieldsValue(item);
      setIsEdit(true);
      setEditUserId(item.id);
    }
    setIsModalOpen(true);
  };
  // 弹窗确认按钮
  const handledOk = () => {
    userForm.validateFields()
      .then(res => {
        const api = isEdit ? updateUsers : postUsers;
        api({
          ...res,
          id: editUserId
        })
          .then(res => {
            message.success(isEdit ? '修改成功' : '添加成功');
            getUsers();
            handleCancel();
          })
          .catch(err => {
            message.error(err.msg);
          });
      })
      .catch((err) => {
        console.log(err);
        message.error('请填写完必要选项！');
      });
  };
  // 弹窗取消按钮
  const handleCancel = () => {
    userForm.setFieldsValue(formData);
    userForm.resetFields();
    setIsModalOpen(false);
    setIsEdit(false);
  };
  // 确认删除
  const deletConfirm = (uid) => {
    messageApi
      .open({
        type: 'loading',
        content: '正在删除中...',
        duration: 1
      })
      .then(() => deleteUsers(uid))
      .then(res => {
        res.success && message.success('删除成功');
        getUsers();
      })
      .catch(err => {
        message.error(err.msg);
      });
  };
  return (
    <div className={styles.box}>
      <Space>
        <Button type="primary" onClick={() => {showModal()}}>添加用户</Button>
        <Modal
          title={!isEdit ? '添加用户' : '编辑用户'}
          okText="确认"
          cancelText="取消"
          open={isModalOpen}
          onOk={handledOk}
          onCancel={handleCancel}
        >
          <Form form={userForm} layout='vertical' initialValues={formData}>
            {
              !isEdit &&
              <>
                <Form.Item label="账号" name="account" rules={[ { required: true, message: '请输入账号!' } ]}>
                  <Input />
                </Form.Item>
              </>
            }
            <Form.Item label="密码" name="password" rules={[ { required: !isEdit, message: '请输入密码!' } ]}>
              <Input />
            </Form.Item>
            <Form.Item label="用户名" name="user_name" rules={[ { required: true, message: '请输入用户名!' } ]}>
              <Input />
            </Form.Item>
            <Form.Item label="头像" name="avatar">
              <UploadImage />
            </Form.Item>
          </Form>
        </Modal>
      </Space>
      <div className={styles.user}>
        <Table
          loading={tableLoading}
          pagination={{position: ['none']}}
          dataSource={dataSource}
        >
          <Column title="用户账号" dataIndex="account" key="account" />
          <Column title="用户名" dataIndex="user_name" key="user_name" />
          <Column title="头像" dataIndex="avatar" key="avatar" render={(tags) => (
            <Avatar shape="square" src={<img src={tags} alt="avatar" />} />
          )} />
          <Column title="角色权限" dataIndex="role" key="role" minWidth={120} render={(tags) => (
            <span>{tags === 1 ? '超级管理员' : '普通成员'}</span>
          )} />
          <Column title="操作" dataIndex="role" key="role" render={(tags, record) => (
            <>
              {
                record.role === 2 &&
                <Space>
                  <Button type="primary" onClick={() => {showModal(record)}}>编辑</Button>
                  {contextHolder}
                  <Popconfirm
                    title="删除用户"
                    description="确定删除这个用户吗?"
                    okText="是的"
                    cancelText="不"
                    onConfirm={() => deletConfirm(record.id)}
                    icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                  >
                    <Button type="primary" danger>删除</Button>
                  </Popconfirm>
                </Space>
              }
            </>
          )} />
        </Table>
      </div>
    </div>
  );
}
