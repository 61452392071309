import Axios from 'axios';
import { Modal } from 'antd';
import { getUserInfoFromCookie, clearUserInfoCookie } from '@util';

class HttpRequest {
    constructor() {
        this.options = {
            method: '',
            url: ''
        };
        // 存储请求队列
        this.queue = {};
    }

    // 销毁请求实例
    destroy(url, message = 'Request canceled') {
        if (this.queue[url]) {
            this.queue[url].cancel(message); // 取消请求
            delete this.queue[url];
            const queue = Object.keys(this.queue);
            return queue.length;
        }
    }

    // 清空整个请求队列
    clearQueue() {
        console.log(this.queue);
        Object.keys(this.queue).forEach((requestId) => {
            this.destroy(requestId);
        });
    }

    // 请求拦截
    interceptors(instance, url, noMsg = false) {
        // 添加请求拦截器
        instance.interceptors.request.use(
            (config) => {
                return config;
            },
            (error) => {
                // 对请求错误做些什么
                return Promise.reject(error);
            }
        );

        // 添加响应拦截器
        instance.interceptors.response.use(
            (res) => {
                const { data } = res;
                if (data.code !== 0) {
                    return Promise.reject(data);
                }  else {
                    return Promise.resolve(data.data);
                }
            },
            (error) => {
                // 用户权限失效，重新登录
                if (error && error.response.status === 403 && error.response.data.code === 10000) {
                    clearUserInfoCookie();
                    Modal.warning({
                        title: '用户登录凭证失效!',
                        content: (
                            <>
                                已退出登录，点击确定返回首页
                            </>
                        ),
                        onOk: () => {
                            window.location.replace('/pages/home/');
                        }
                    });
                    // Modal.info({
                    //     title: '登录信息过期，请重新登录',
                    //     okText: '跳转登录页面',
                    //     content: '',
                    //     onOk: () => {
                    //         window.location.href = '/';
                    //     }
                    // });
                }
                return Promise.reject(error && error.response.data);
            }
        );
    }

    // 创建实例
    create(headers, source) {
        const { token, userInfo } = getUserInfoFromCookie();
        const conf = {
            baseURL: '',
            // timeout: 2000,
            cancelToken: source.token, // 将 CancelToken 关联到请求
            headers: {
                token: token,
                uid: userInfo.id,
                'Content-Type': 'application/json; charset=utf-8',
                'X-URL-PATH': window.location.pathname,
                ...headers
            }
        };
        return Axios.create(conf);
    }

    // 请求实例
    request(options) {
        const source = Axios.CancelToken.source();
        const instance = this.create(options.headers || {}, source);
        this.interceptors(instance, options.url, options.noMsg || false);
        options = Object.assign({}, options);
        this.queue[options.url] = {
            instance,
            cancel: source.cancel
        };
        return instance(options);
    }
}

const httpRequest = new HttpRequest();

export default httpRequest;