import axios from '@libs/axios';
import { API_HOST } from './api_config';

const API_HOST_URL = () => `${API_HOST}/api/v1`;
export const API_LIST = {
    UPLOAD: () => `${API_HOST_URL()}/file/upload`,
    LOGIN: () => `${API_HOST_URL()}/users/login`,
    LOGOUT: () => `${API_HOST_URL()}/users/logout`,
    USER: () => `${API_HOST_URL()}/users`,
    USER_LIST: () => `${API_HOST_URL()}/users/list`,

    ARTICLE: () => `${API_HOST_URL()}/articles`,

    PAGES: () => `${API_HOST_URL()}/pages`
};

// 根据urlKey销毁请求实例
export const destroyQueue = (urlKey) => {
    axios.destroy(API_LIST[urlKey]());
};

// 清空整个请求队列
export const clearQueue = () => {
    axios.clearQueue();
};

// 根据urlKey销毁请求实例
export const uploadFile = () => {
    return API_LIST.UPLOAD();
};

// 登录
export const postLogin = ({ account, password }) => {
    return axios.request({
        url: API_LIST.LOGIN(),
        method: 'POST',
        data: { account, password }
    });
};

// 退出登录
export const putLogout = () => {
    return axios.request({
        url: API_LIST.LOGOUT(),
        method: 'PUT'
    });
};

// 获取用户信息
export const getUsers = () => {
    return axios.request({
        url: API_LIST.USER(),
        method: 'GET'
    });
};

// 获取用户列表
export const getUsersList = () => {
    return axios.request({
        url: API_LIST.USER_LIST(),
        method: 'GET'
    });
};

// 添加用户
export const postUsers = ({ account, user_name, password, avatar }) => {
    return axios.request({
        url: API_LIST.USER(),
        method: 'POST',
        data: { account, user_name, password, avatar }
    });
};

// 更新用户
export const updateUsers = ({ id, password, user_name, avatar }) => {
    return axios.request({
        url: `${API_LIST.USER()}/${id}`,
        method: 'PUT',
        data: { password, user_name, avatar }
    });
};

// 删除用户
export const deleteUsers = (id) => {
    return axios.request({
        url: `${API_LIST.USER()}/${id}`,
        method: 'DELETE'
    });
};


/**
 * 文章相关接口
 */
// 获取列表
export const getArticle = () => {
    return axios.request({
        url: API_LIST.ARTICLE(),
        method: 'GET'
    });
};

// 添加文章
export const addArticle = ({ title, banner, content, type, home_type, remark }) => {
    return axios.request({
        url: API_LIST.ARTICLE(),
        method: 'POST',
        data: { title, banner, content, type, home_type, remark }
    });
};

// 更新文章
export const updateArticle = ({ id, title, banner, content, type, home_type, remark }) => {
    return axios.request({
        url: `${API_LIST.ARTICLE()}/${id}`,
        method: 'PUT',
        data: { title, banner, content, type, home_type, remark }
    });
};

//删除文章
export const deleteArticle = (id) => {
    return axios.request({
        url: `${API_LIST.ARTICLE()}/${id}`,
        method: 'DELETE'
    });
};

// 首页内容
export const getPages = () => {
    return axios.request({
        url: API_LIST.PAGES(),
        method: 'GET'
    });
};

// 根据类型获取列表
export const getPagesByType = (type) => {
    return axios.request({
        url: `${API_LIST.PAGES()}/${type}`,
        method: 'GET'
    });
};

// 根据类型和ID获取内容
export const getPagesByTypeAndId = ({type, id}) => {
    return axios.request({
        url: `${API_LIST.PAGES()}/${type}/${id}`,
        method: 'GET'
    });
};