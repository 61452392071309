export const ENV = require('./config.js').env;

export const HOST = 'www.pmkit.cn';

export const API_HOSTS = {
    test: `http://localhost:3001`,
    dev: `http://localhost:3001`,
    prod: `https://${HOST}`
};

export const API_HOST = `${API_HOSTS[ENV]}`;