import { useEffect, useState } from 'react';
import { Space, Image, Button, Table, Popconfirm, message, Drawer, Form, Input, Select } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from '@libs/markdownIt';
// 导入编辑器的样式
import 'react-markdown-editor-lite/lib/index.css';

import styles from './index.module.less';
import UploadImage from '@components/UploadImage';
import { getArticle, deleteArticle, addArticle, updateArticle } from '@api';
import { normalRequestErrorHandler } from '@util';

export default function ArticleManagement() {
  const [tableLoading, setTableLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const { Column } = Table;
  const [messageApi, contextHolder] = message.useMessage();

  const articleType = [
    { value: 'css', label: 'CSS' },
    { value: 'javascript', label: 'JAVASCRIPT' },
    { value: 'framework', label: '框架' },
    { value: 'tools', label: '工具' },
    { value: 'other', label: '其他' },
    { value: 'nodejs', label: 'NODEJS' },
    { value: 'mysql', label: 'MYSQL' },
    { value: 'server', label: '服务器' },
    { value: 'app', label: 'APP' },
    { value: 'mini-program', label: '小程序' }
  ];

  const homeType = [
    { value: 1, label: 'Banner' },
    { value: 2, label: '干货分享' },
    { value: 3, label: '常见问题' }
  ];

  useEffect(() => {
    // 这个代码相当于 componentDidMount
    getArticles();
    // 可选：返回一个清理函数，相当于 componentWillUnmount
    return () => {
    };
  }, []); 

  // 获取用户列表
  const getArticles = () => {
    setTableLoading(true);
    getArticle()
      .then(res => {
        res.forEach(e => e.key = e.id);
        setDataSource(res);
        setTableLoading(false);
      })
      .catch((err) => {
        setTableLoading(false);
        normalRequestErrorHandler(err);
      });
  };

  // 显示弹窗
  const [isEdit, setIsEdit] = useState(false);
  const [editArticleId, setEditArticleId] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const showDrawer = (item) => {
    console.log(item);
    if (item) {
      articleForm.setFieldsValue(item);
      setIsEdit(true);
      setEditArticleId(item.id);
    }
    setIsDrawerOpen(true);
  };
  // 关闭弹窗
  const hideDrawer = () => {
    articleForm.setFieldsValue(formData);
    articleForm.resetFields();
    setIsDrawerOpen(false);
    setIsEdit(false);
  };
  // 确认删除文章
  const deletConfirm = (id) => {
    messageApi
      .open({
        type: 'loading',
        content: '正在删除中...',
        duration: 1
      })
      .then(() => deleteArticle(id))
      .then(res => {
        res.success && message.success('删除成功');
        getArticles();
      })
      .catch(err => {
        message.error(err.msg);
      });
  };

  const [articleForm] = Form.useForm();
  // 表单初始化参数
  const formData = {
    title: null,
    banner: null,
    type: null,
    home_type: null,
    content: ''
  };
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  // Markdown解析器
  const handleEditorChange = ({text, html}) => {
    articleForm.setFieldsValue({ content: text });
  }
  // 表单提交保存
  const onSave = () => {
    articleForm.validateFields()
      .then(res => {
        const api = isEdit ? updateArticle : addArticle;
        api({
          ...res,
          id: editArticleId
        })
          .then(res => {
            message.success(isEdit ? '修改成功' : '添加成功');
            getArticles();
            hideDrawer();
          })
          .catch(err => {
            message.error(err.msg);
          });
      })
      .catch((err) => {
        normalRequestErrorHandler(err);
        message.error('请填写完必要选项！');
      });
  };
  return (
    <div className={styles.article}>
      <Space>
        <Button type="primary" onClick={() => {showDrawer()}}>添加文章</Button>
      </Space>
      <Drawer
        title={`添加文章`}
        placement="right"
        width={1280}
        onClose={hideDrawer}
        open={isDrawerOpen}
        footer={(
          <Space>
            <Button onClick={hideDrawer}>取消</Button>
            <Button onClick={onSave} type="primary">确定</Button>
          </Space>
        )}
      >
        <Form form={articleForm} layout='vertical' initialValues={formData}>
          <Form.Item label="文章标题" name="title" rules={[ { required: true, message: '请输入文章标题!' } ]}>
            <Input placeholder="文章标题" />
          </Form.Item>
          <Form.Item label="文章简介" name="remark" rules={[ { required: true, message: '请输入文章简介!' } ]}>
            <Input placeholder="文章简介" />
          </Form.Item>
          <Form.Item label="文章封面" name="banner" rules={[ { required: true, message: '请上传文章封面!' } ]}>
            <UploadImage size={{ width: '300px', height: '300px' }} />
          </Form.Item>
          <Form.Item label="文章类型" name="type" rules={[ { required: true, message: '请选择文章类型!' } ]}>
            <Select
              placeholder="文章类型"
              optionFilterProp="label"
              onChange={onChange}
              options={articleType}
            />
          </Form.Item>
          <Form.Item label="首页模块" name="home_type">
            <Select
              placeholder="首页模块"
              optionFilterProp="label"
              onChange={onChange}
              options={homeType}
            />
          </Form.Item>
          <Form.Item label="文章内容" name="content" rules={[ { required: true, message: '请输入文章内容!' } ]}>
            <MdEditor style={{ height: '500px' }} renderHTML={text => MarkdownIt.render(text)} onChange={handleEditorChange}/>
          </Form.Item>
        </Form>
      </Drawer>
      <div className={styles.table}>
        <Table
          loading={tableLoading}
          pagination={{position: ['none']}}
          dataSource={dataSource}
        >
          <Column title="文章标题" dataIndex="title" key="title" />
          <Column title="文章简介" dataIndex="remark" key="remark" />
          <Column title="文章封面" dataIndex="banner" key="banner" render={(tags, record) => {
            return (
              <>
                <Image
                  width={200}
                  src={tags}
                />
              </>
            )
          }} />
          <Column title="文章类型" dataIndex="type" key="type" render={(tags, record) => {
            const data = articleType.find(item => item.value === tags);
            return (
              <>
                { data && data.label }
              </>
            )
          }} />
          <Column title="首页模块" dataIndex="home_type" key="home_type" render={(tags, record) => {
            const data = homeType.find(item => item.value === tags);
            return (
              <>
                { data && data.label }
              </>
            )
          }} />
          <Column title="操作" dataIndex="role" key="role" render={(tags, record) => (
            <>
              {
                <Space>
                  <Button type="primary" onClick={() => {showDrawer(record)}}>编辑</Button>
                  {contextHolder}
                  <Popconfirm
                    title="删除文章"
                    description="确定删除这篇文章吗?"
                    okText="是的"
                    cancelText="不"
                    onConfirm={() => deletConfirm(record.id)}
                    icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                  >
                    <Button type="primary" danger>删除</Button>
                  </Popconfirm>
                </Space>
              }
            </>
          )} />
        </Table>
      </div>
    </div>
  )
}
