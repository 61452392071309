import React from 'react'
import { Outlet } from 'react-router-dom';
import { Footer as AntdMFooter } from 'antd-mobile';
import Navigation from '@components/Navigation';


export default function Pages() {
  return (
    <>
      <Navigation/>
      <Outlet/>
      <AntdMFooter 
          className='footer'
          label='没有更多了'
          content='@ 2024-2024 All rights reserved'
      />
    </>
  )
}
