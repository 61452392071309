import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper } from 'antd-mobile';
import './index.less';
const fight = 'https://pmk.oss-cn-shenzhen.aliyuncs.com/pmk_web/image/fight.png';

// const colors = [
//   {id: 1, color: '#ace0ff', tag: 'Technology', title: '🚀 免费chatgpt3.5合集，部分还支持chatpgt4', remark: 'ChatGPT 镜像提供了海量优质内容，包括编程语言、框架、算法、数据结构、前端开发、后端开发、移动开发等各个方面的技术文章和教程，本文推荐优质的ChatGPT镜像，供大家学习使用。'},
//   {id: 2, color: '#bcffbd', tag: 'Webpack', title: 'webpack.ProvidePlugin🔥', remark: '一个强大的工具，它可以帮助我们减少重复的导入语句，使代码更加干净整洁'},
//   {id: 3, color: '#e4fabd', tag: '', title: '', remark: ''},
//   {id: 4, color: '#ffcfac', tag: '', title: '', remark: ''},
// ];

export default function Banner(props) {
    const ref = useRef(null);
    const { list } = props;
    const navlinkFunc = useNavigate();
    const navlink = (item) => {
      navlinkFunc(`/pages/article/${item.type}/${item.id}`);
    }
    return (
      <div className='content banner'>
        <div className="banner-item banner-left">
          <Swiper
            loop
            autoplay={false}
            ref={ref}
            style={{
              '--track-padding': ' 0 0 16px',
            }}
            indicator={(total, current) => {
              return (
                <div
                  className='banner-customIndicator'
                >
                  {
                    list.map((item, index) => (
                      <div
                        key={index}
                        className={current === index ?'item active' : 'item'}
                        onClick={() => {
                          console.log(ref.current, index);
                          ref.current?.swipeTo(index)
                        }}
                      ></div>
                    ))
                  }
                </div>
              )
            }}
          >
            {
              list.map((item, index) => (
                <Swiper.Item key={index} onClick={() => navlink(item)}>
                  <div className='banner-content'>
                    {
                      item.type_desc ? <div className='tag'><span>{item.type_desc}</span></div> : ''
                    }
                    <h2 className='title'><span>{item.title}</span></h2>
                    <p className='remark'>{item.remark}</p>
                  </div>
                </Swiper.Item>
              ))
            }
          </Swiper>
        </div>
        <div className='banner-item banner-right'>
          <img src={fight} alt="right_banner" />
        </div>
      </div>
    )
}
