import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import MarkdownIt from '@libs/markdownIt';
// import ReactMarkdown from 'react-markdown';
import { Skeleton } from 'antd-mobile'
import { getPagesByTypeAndId } from '@api';
import { normalRequestErrorHandler } from '@util';
import styles from './index.module.less';
export default function Article() {
  // 使用 useLocation Hook 来获取传递的 state
  const params = useParams();
  const { id, key } = params || {}; // 如果 state 为空，提供默认值
  const [articleData, setArticleData] = useState({});
  const [loading, setLoading] = useState(false);

  // 获取用户列表
  const getPagesByTypeAndIdData = useCallback(() => {
    setLoading(true);
    getPagesByTypeAndId({type: key, id})
      .then(res => {
        setArticleData(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        normalRequestErrorHandler(err);
      });
  }, [key, id]);

  useEffect(() => {
    // 这个代码相当于 componentDidMount
    getPagesByTypeAndIdData();
    // 可选：返回一个清理函数，相当于 componentWillUnmount
    return () => {
    };
  }, [params, getPagesByTypeAndIdData]);

  return (
    <div className={styles.article}>
      <div className={styles['article-header']}>
        <h1 className={styles['article-header-title']}>{ loading ? <Skeleton.Title animated style={{width: '100%'}} /> : articleData.title }</h1>
      </div>
      <div className={styles['article-box']}>
        {
          loading ?
          <Skeleton.Title animated style={{width: '100%'}} />
          : <div className={styles['markdown-body']} dangerouslySetInnerHTML={{ __html: MarkdownIt.render(articleData.content || '') }}></div>
          // : <ReactMarkdown>{articleData.content || ''}</ReactMarkdown>
        }
      </div>
    </div>
  )
}
