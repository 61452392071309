import React, { Component } from 'react';
import { LocationOutline } from 'antd-mobile-icons';
import { NavLink } from 'react-router-dom';
import Banner from '@components/Banner';
import { getPages } from '@api';
import './index.less';
import { normalRequestErrorHandler } from '@util';

export default class Home extends Component {

  state = {
    scrollY: 0,
    banner: [],
    dry_goods: [],
    question: []
  }

  homeRef = React.createRef();

  componentDidMount() {
    this.getPages();
    window.addEventListener('scroll', this.scrollFunc);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollFunc);
  }

  scrollFunc = (e) => {
    this.setState({scrollY: window.scrollY});
  }

  toTop = () => {
    // 到顶部完成时间0.5秒
    let finished_time = 500;
    // 每0.01秒向上运动一次
    let interval_time = 10;
    // 当前位置距离顶部距离
    let scrollTop = window.scrollY;
    // 计算每次间隔向上滑动多少像素
    let top_pixel = scrollTop / finished_time * interval_time;
    // 通过定时器进行间隔向上滑动
    let timer = setInterval(() => {
        scrollTop -= top_pixel;
        // 为负数，浏览器会不处理得
        window.scrollTo(0, scrollTop);
        if (scrollTop <= 0) {
            clearInterval(timer)
        }
    }, interval_time)
  }

  getPages = () => {
    getPages()
      .then(res => {
        this.setState(res);
      })
      .catch(err => {
        normalRequestErrorHandler(err);
      });
  }

  render() {
    return (
      <div ref={this.homeRef} className='home'>
        <Banner list={this.state.banner}/>
        <div className='home-content'>
          <div className='card'>
            <div className='card-top'>
              <div className='card-top-more'>
                <div className='card-top-item'></div>
                <div className='card-top-item'></div>
                <div className='card-top-item'></div>
                <div className='card-top-item'></div>
                <div className='card-top-item'></div>
              </div>
              <div className='card-top-item'></div>
            </div>
            <div className='card-box'>
              <h2>Welcome to PMK personal website！</h2>
              <p>这里有个人的技术分享，干货分享，常见问题记录！👻👻👻</p>
            </div>
          </div>
        </div>
        <div className='home-content'>
          <div className='dry'>
            <div className='title'>
              <h2>干货分享</h2>
              <p>知识，一份知识，就是一份财富，一份看清，就是一份生活，一份了解，就是一份健康，一份经验，就是一份才华。</p>
            </div>
            <div className='dry-box'>
              {
                this.state.dry_goods.map((item, index) => (
                  <NavLink key={item.id} className='dry-item' to={`/pages/article/${item.type}/${item.id}`}>
                    <div className='img'>
                      <img src={item.banner} alt={item.title} />
                    </div>
                    <h2>{item.title}</h2>
                    <p className='desc'>{item.remark}</p>
                  </NavLink>
                ))
              }
            </div>
            <div className='more'>
              <NavLink style={{color: '#000'}} to={`/pages/article/all`} state={{ key: 'all', title: '更多列表' }}>
                <span >查看更多</span>
              </NavLink>
            </div>
          </div>
        </div>
        <div className='home-content'>
          <div className='dry question'>
            <div className='title'>
              <h2>常见问题</h2>
            </div>
            <div className='dry-box'>
              {
                this.state.question.map((item, index) => (
                  <NavLink key={item.id} className='dry-item' to={`/pages/article/${item.type}/${item.id}`}>
                    <div className='img'>
                      <img src={item.banner} alt={item.title} />
                    </div>
                    <h2>{item.title}</h2>
                    <p className='desc'>{item.remark}</p>
                  </NavLink>
                ))
              }
            </div>
            <div className='more'>
              <NavLink style={{color: '#000'}} to={`/pages/article/all`} state={{ key: 'all', title: '更多列表' }}>
                <span >查看更多</span>
              </NavLink>
            </div>
          </div>
        </div>
        <div className={`toTop ${this.state.scrollY >= 600 ? 'active' : ''}`} onClick={this.toTop}>
          <LocationOutline fontSize={36}/>
        </div>
      </div>
    )
  }
};
