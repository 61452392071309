import { Navigate } from 'react-router-dom';
import Admin from '@pages/Admin';
import Pages from '@pages/Pages';
import ArticleManagement from '@pages/ArticleManagement';
import UserManagement from '@pages/UserManagement';
import { getUserInfoFromCookie } from '@util';

import Home from '@pages/Home';
import ArticleList from '@pages/ArticleList';
import Article from '@pages/Article';

const RequireAuth = ({ children }) => {
    const { userInfo: {
        id,
    }, token } = getUserInfoFromCookie();
    if (!id && !token) {
      // 用户未登录，重定向到登录页面
      return <Navigate to="/pages/home" />;
    }
  
    // 用户已登录，允许访问受保护的页面
    return children;
};

// 路由列表
export const routes = [
    {
        path: '/admin', 
        element: (
            <RequireAuth>
                <Admin/>
            </RequireAuth>
        ),
        children: [
            {
                path: 'article_management', 
                element: <ArticleManagement/>
            },
            {
                path: 'user_management', 
                element: <UserManagement/>
            },
            {
                path: '/admin',
                element: <Navigate to="article_management"/>
            }
        ]
    },
    {
        path: '/pages',
        element: <Pages/>,
        children: [
            {
                path: 'home', 
                element: <Home/>
            },
            {
                path: 'article/:key', 
                element: <ArticleList/>
            },
            {
                path: 'article/:key/:id', 
                element: <Article/>
            }
        ]
    },
    {
        path: '/',
        element: <Navigate to="/pages/home"/>
    }
];